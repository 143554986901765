<template>
    <div>
      <el-descriptions
      class="margin-top"
      title=""
      :column="3"
      :size="medium"
      border
      >
        <el-descriptions-item >
          <template #label>
            <el-icon></el-icon>
            单据类型
          </template>
          标签申请
        </el-descriptions-item>
        <el-descriptions-item >
          <template #label>
            <el-icon></el-icon>
            制单人
          </template>
          {{labelApplyInfo.creatorName}}
        </el-descriptions-item>
        <el-descriptions-item >
          <template #label>
            <el-icon></el-icon>
            制单时间
          </template>
          {{labelApplyInfo.createTime}}
        </el-descriptions-item>
        <el-descriptions-item >
          <template #label>
            <el-icon></el-icon>
            送办人
          </template>
          {{labelApplyInfo.deliverName}}
        </el-descriptions-item>
        <el-descriptions-item >
          <template #label>
            <el-icon></el-icon>
            送办时间
          </template>
          {{labelApplyInfo.updateTime}}
        </el-descriptions-item>
        <el-descriptions-item >
          <template #label>
            <el-icon></el-icon>
            申请标签（套）
          </template>
          {{labelApplyInfo.labelCount}}
        </el-descriptions-item>
        <el-descriptions-item >
          <template #label>
            <el-icon></el-icon>
            发布标签（套）
          </template>
          {{labelApplyInfo.releaseCount}}
        </el-descriptions-item>
    </el-descriptions><br>
    </div>
</template>

<script>
// import Api from '@/apis/api'
export default {
  props:{
    labelApplyInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
    // workflowApi:{
    //   type:Api,
    //   default: null
    // }
  },
  components:{},
  data(){
    return {
      // labelApplyInfo: {
      // }
    }
  },
  computed: {
  },
  watch:{
    //  id:{
    //     handler: function(newV,oldV){
    //       this.getApplyInfo()
    //     }
    // },
  },
  mounted(){
      // this.getApplyInfo()
  },
  methods:{
  //  getApplyInfo(){
  //     this.workflowApi.getById(this.id).then(resp=>{
  //         this.labelApplyInfo = resp.data
  //     })
  //  }
  }

}
</script>

<style>

</style>
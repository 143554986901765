<template>
    <el-dialog ref="dialog"  :title="workflowTitle" v-model="workflowDialogShow" :append-to-body="true" :width="dialogWidth" destroy-on-close>
        <template v-if="workflowFormKey">
            <el-divider content-position="left">标签申请信息</el-divider>
            <el-row>
                <el-col>
                    <component :is="labelApplyInfoForm" :labelApplyInfo="businessObject" :workflowApi="workflowApi"></component>
                </el-col>
            </el-row>
        </template>
        <el-divider content-position="left">历史记录</el-divider>
        <el-row>
            <el-table :data="historicNodes" border fit size="mini" highlight-current-row stripe>
                <el-table-column label="处理人" width="120" prop="assignee">
                    <template #default="scope">
                        {{scope.row.deleteReason?this.creatorName:scope.row.taskLocalVariables.name?scope.row.taskLocalVariables.name[scope.row.assignee]:'--'}}（{{scope.row.deleteReason?this.creater:scope.row.assignee}}）
                    </template>
                </el-table-column>
                <el-table-column label="角色" width="140" prop="name">
                    <template #default="scope">
                        {{scope.row.deleteReason?'业务部':scope.row.name}}（{{scope.row.deleteReason?this.creater:scope.row.assignee}}）
                    </template>
                </el-table-column>
                <el-table-column label="开始时间" width="140" prop="startTime"></el-table-column>
                <el-table-column label="办理时间" width="140" prop="endTime"></el-table-column>
                <el-table-column label="处理结果" width="80" prop="taskLocalVariables.approved">
                    <template #default="scope">
                        {{scope.row.endTime?(scope.row.deleteReason?'终止':scope.row.taskLocalVariables.approved?'同意':'退回'):'待处理'}}
                    </template>
                </el-table-column>
                <el-table-column label="处理意见" prop="taskLocalVariables.note">
                    <template #default="scope">
                        {{scope.row.endTime?(scope.row.deleteReason?scope.row.deleteReason:scope.row.taskLocalVariables.note):'--'}}
                    </template>
                </el-table-column>
            </el-table>
        </el-row>   
        <template v-if="task && taskId">
            <el-divider content-position="left">流程处理</el-divider>
            <el-row>
                <el-col>
                    <component :is="workflowFormKey" v-model:params="params" :workflowApi="workflowApi" :isQualityManager="isQualityManager" @isSave="isSave"></component>
                </el-col>
            </el-row>
        </template>
        <template #footer v-if="task && taskId">
            <el-button size="mini"  @click="onClickCancelButton"  v-waves>取消</el-button>
            <el-button size="mini" type="primary" v-waves  @click="onClickConfirmButton" :disabled ="workflowFormKey && workflowFormKey != 'taskForm' && isSaveflag">确认</el-button>
        </template>
    </el-dialog>
</template>

<script>
import workflowComs from '@/components/workflow/autoRegister'
import Api from '@/apis/api'
export default {
    name: 'Workflow',
    components:{
         ...workflowComs,
    },
    props:{
        workflowNode:{
            type: Object
        },
        workflowApi:{
            type:Api,
            default: null
        }
    },
    data() {
        return {
            dialogWidth:'50%',
            workflowTitle:'',
            workflowDialogShow: false,
            workflowFormKey: null,
            isQualityManager: false,
            task: null,
            taskId: null,
            labelApplyInfoForm:'labelApplyInfoForm',
            params:{
                businessObject:this.businessObject,
                localVariables:{
                    approved:null,
                    note:''
                }
            },
            historicNodes:[],
            isSaveflag: true,
            labelApplyId:null,
            creatorName: '',
            creater: ''
        }
    },
    computed:{
        businessObject(){
            return this.workflowNode.businessObject
        }
    },
    watch:{
        workflowNode:{
            handler: function(newV,oldV){
                this.task = null
                this.taskId = null
                this.isQualityManager = false
                this.params.businessObject = {... this.businessObject}
                this.init()
                this.resetLocalVariables()
            },
            deep: true,
        },
    },
    created(){
        this.init()
    },
    methods:{
        isSave(isflag){
          this.isSaveflag = isflag
        },
        init(){
            this.labelApplyId = this.params.businessObject?this.params.businessObject.id:undefined
            this.creatorName = this.params.businessObject?this.params.businessObject.creatorName:''
            this.creater = this.params.businessObject?this.params.businessObject.createBy:''
            if (this.workflowNode && this.workflowNode.processInstanceId) {
                Promise.all([
                        this.workflowApi.getHistories(this.workflowNode.businessKey),
                    ]).then(resp=>{
                    // 流程历史记录
                    this.historicNodes = resp[0].data//.slice(0,resp[0].data.length-1)
                }).catch(err=>{
                    console.log('[ err ] >', err)
                })
                this.workflowApi.getTasks({assignee:this.workflowNode.assignee,processDefinitionKeys:[this.workflowNode.processDefinitionKey],businessKey:this.workflowNode.businessKey}).then(resp=>{
                    var task = resp.data[0]
                    if (task) {
                        this.task = task
                        this.taskId = task.id
                        this.workflowFormKey = task.formKey || 'taskForm'
                        // this.workflowFormKey = 'taskForm'
                        this.workflowTitle = task.name === '品质部'?'发码审核':(task.name === '业务部'?'标签申请修改':'单据审核')
                        if(task.name === '业务部' ){
                          this.dialogWidth = '80%'
                        }else{
                          this.dialogWidth = '60%'
                        }
                        if(task.name === '品质部'){
                            this.workflowApi.getVariables(task.id).then(resp=>{
                                if(resp.data && (!resp.data.assignee2)){
                                    this.isQualityManager = true
                                }
                            })
                        }

                    }else{
                        // this.$message.error("不存在任务")
                        // this.workflowDialogShow = false
                        this.task = null
                        this.taskId = null
                    }
                })
                this.workflowDialogShow = true
            }
        },
        onClickConfirmButton(){
            if(this.workflowFormKey === 'taskForm'){
                if((!this.params.localVariables) || this.params.localVariables.approved === null){
                    this.$message.warning("请选择【处理结果】")
                    return
                }
                if(this.task && this.task.name === '品质部' && this.params.localVariables.approved === true
                    && this.params.localVariables.nextApprover === null && this.isQualityManager === true){
                    this.$message.warning("请选择【采购部审批人】")
                    return
                }
            }
            if(this.workflowFormKey != 'taskForm'){
                var localVariables = {
                    approved: true,
                    note: '修改物料'
                }
                this.params.localVariables = localVariables
            }
            this.workflowApi.audit({taskId:this.taskId,businessKey:this.workflowNode.businessKey,...this.params}).then(resp=>{
                console.log('[ resp ] >', resp)
                if (resp.data) {
                    this.workflowDialogShow = false
                    this.resetLocalVariables()
                    this.$emit('complete')
                }
            })
        },
        onClickCancelButton(){
            this.workflowDialogShow = false
            this.resetLocalVariables()
        },
        resetLocalVariables(){
            this.params.localVariables = {
                approved: null,
                note: ''
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
<template>
  <el-form label-position="top">
      <el-form-item label="审核意见">
          <el-input v-model="note" :rows="4" type="textarea" placeholder="请输入流程审核意见" maxlength="255" show-word-limit clearable></el-input>
      </el-form-item>
     <el-form-item label="处理结果">
        <el-radio-group v-model="approval">
            <el-radio v-model="radio" :label="true">通过</el-radio>
            <el-radio v-model="radio" :label="false">退回</el-radio>
        </el-radio-group>
     </el-form-item>
     <el-form-item label="采购部审批人" v-if="isQualityManager && approval">
      <el-select v-model="nextApprover" filterable placeholder="请选择采购部审核人">
        <el-option
          v-for="item in purchaseManagerTable"
          :key="item.account"
          :label="item.name"
          :value="item.account">
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import Api from '@/apis/api'
export default {
    emits: ["update:params"],
    props:{
        params:{
            type: Object,
            // default: {
            //     variables:[],
            //     localVariables:[]
            // }
        },
        workflowApi:{
            type:Api,
            default: null
        },
        isQualityManager:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            approval: null,
            note: '',
            variables:{},
            localVariables:{},
            nextApprover: null,
            purchaseManagerTable: []
        }
    },
    watch:{
        approval:{
            handler: function(newV,oldV){
                this.localVariables.approved = this.approval
                this.localVariables.note = this.note
                if(this.approval === true){
                    this.localVariables.nextApprover = this.nextApprover
                }
                this.$emit('update:params', {variables:this.variables,localVariables: this.localVariables})
            }
        },
        note:{
            handler: function(newV,oldV){
                this.localVariables.note = this.note
                this.localVariables.approved = this.approval
                if(this.approval === true){
                    this.localVariables.nextApprover = this.nextApprover
                }
                this.$emit('update:params', {variables:this.variables,localVariables: this.localVariables})
            }
        },
        nextApprover:{
            handler: function(newV,oldV){
                this.localVariables.note = this.note
                this.localVariables.approved = this.approval
                if(this.approval === true){
                    this.localVariables.nextApprover = this.nextApprover
                }
                this.$emit('update:params', {variables:this.variables,localVariables: this.localVariables})
            }
        }
    },
    created(){
        this.getManagerList()
    },
    methods:{
        getManagerList(){
            this.workflowApi.getByKey("purchaseManager").then(res =>{
                if(res.data && res.data.id && res.data.value){
                    this.purchaseManagerTable = JSON.parse(res.data.value)
                }
            })
        }
    }
}
</script>

<style>

</style>
const context = require.context("./form", true, /.*.(vue|js)$/);

var coms = {};

context.keys().forEach((key) => {
  var ks = key.split("/");
  var formName = ks[ks.length - 1].split(".")[0];

  // 获取文件中的 default 模块
  const file = context(key).default;
  coms[formName] = { ...file };
});

export default coms;
